<template>
    <div class="bg-white py-6">
        <div class="max-w-full mx-auto lg:max-w-7xl">
            <Container class="relative">
                <img src="/img/mobile_desktop.png" class="hidden md:inline-block">
                <img src="/img/mobile_mobile.png" class="md:hidden w-full">
                <div class="absolute bottom-2 left-0 md:left-9  md:pl-6 text-[#2d2a34] text-xl md:text-[32px] font-normal md:leading-10 w-full p-10">
                    <div class="hidden md:block">
                    Установите мобильное приложение <br>
                    Клиники Фомина <br>
                    </div>
                    <div class="block md:hidden text-center md:text-left">
                    Установите мобильное<br> приложение
                    Клиники Фомина <br>
                    </div>
                    <div class="mt-3 flex md:flex-row flex-col gap-2 w-full md:w-auto">
                        <a class="block w-full md:w-auto" target='blank' href="https://apps.apple.com/ru/app/%D0%BA%D0%BB%D0%B8%D0%BD%D0%B8%D0%BA%D0%B0-%D1%84%D0%BE%D0%BC%D0%B8%D0%BD%D0%B0/id1607856482?l=en">
                            <div
                                class="h-12 w-full px-6 py-2 bg-[#2d2a34] rounded-2xl justify-center items-center  justify-start items-center gap-2 inline-flex">
                                <div class="text-white text-sm font-normal font-['Circe'] leading-none">AppStore</div>
                                <div class="w-[20.57px] h-[24.13px] relative">
                                    <svg width="21" height="26" viewBox="0 0 21 26" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.3484 14.7781C16.6342 11.1609 19.79 9.22868 20.0203 9.09343C19.5364 8.44979 18.9743 7.96329 18.4067 7.6107C16.5204 6.4383 14.2228 6.32892 12.856 6.92888C12.8423 6.93429 12.8286 6.94111 12.8149 6.9466C11.481 7.52735 9.96484 7.52735 8.63098 6.9466C8.61724 6.94111 8.60357 6.93429 8.58983 6.92888C7.22306 6.32892 4.92547 6.4383 3.03914 7.6107C1.15281 8.78318 -0.680106 11.4274 0.249393 16.4971C1.17882 21.5668 4.5704 24.6469 4.5704 24.6469C4.5704 24.6469 6.18395 26.0367 8.31705 25.1102C9.64952 24.5308 10.2569 24.4311 10.5241 24.4379C10.6571 24.4406 10.7901 24.4406 10.9217 24.4379C11.1891 24.4311 11.7964 24.5321 13.1288 25.1102C15.2619 26.0367 16.8755 24.6469 16.8755 24.6469C16.8755 24.6469 19.2512 22.4879 20.5714 18.8106C19.4377 18.2886 17.8159 17.1448 17.3484 14.7781Z"
                                            fill="white"/>
                                        <path
                                            d="M10.3036 6.57153C10.3036 6.57153 9.70453 1.48277 15.4285 0.571533L15.4052 1.25422C15.3103 4.07189 13.1107 6.36508 10.3036 6.57153Z"
                                            fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </a>

                        <a class="block w-full md:w-auto" target='blank' href="https://play.google.com/store/apps/details?id=com.kdfapp.prod">
                        <div
                            class="h-12 px-6 py-2 bg-[#2d2a34] rounded-2xl justify-center items-center md:justify-start md:items-center gap-2 inline-flex w-full">
                            <div class="text-white text-sm font-normal font-['Circe'] leading-none">Google Play</div>
                            <div class="w-[20.57px] h-[23.14px] relative">
                                <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.3471 8.16041L12.5701 11.8736L1.48291 0.699273C2.03902 0.366957 2.76349 0.315644 3.40511 0.685209L16.3471 8.16041Z"
                                        fill="#00F076"/>
                                    <path
                                        d="M16.4395 15.7864L3.40419 23.3142C2.62518 23.7645 1.72177 23.5901 1.14893 23.0486L12.5705 11.8743L16.4408 15.7864H16.4395Z"
                                        fill="#F73448"/>
                                    <path
                                        d="M20.2073 13.6112L16.4706 15.7693L16.4408 15.786L12.5718 11.8739L16.3487 8.16064L16.3785 8.17735L20.2086 10.3894C21.4542 11.1092 21.4542 12.8914 20.2086 13.6112H20.2073Z"
                                        fill="#FFCA00"/>
                                    <path
                                        d="M1.14809 23.0476C1.14031 23.04 1.13253 23.0322 1.12475 23.0246C0.787799 22.6948 0.571289 22.2355 0.571289 21.7017V2.29518C0.571289 1.6036 0.935543 1.03644 1.454 0.715685C1.46303 0.709279 1.47345 0.704127 1.48248 0.698975L12.5697 11.8733L1.14809 23.0476Z"
                                        fill="#00D2FF"/>
                                </svg>
                            </div>
                        </div>
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        return {}
    }
}
</script>
